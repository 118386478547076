import { MediaObject } from 'src/api/services/MediaClient';
import {
  RequestPriorityType,
  requestStatus as legacyRequestStatus,
} from 'src/api/services/RequestClient';
import { ValueOfObject } from 'src/types/generics';
import { revisionType as legacyRevisionType } from 'src/api/services/RevisionClient';
import { RevisionResponse } from 'src/lib/services/api/revision-api';
import { VideoTemplateResponse } from 'src/lib/services/api/video-templates-api';
import { UserResponse } from 'src/lib/services/api/user-api';

export const requestStatus = { ...legacyRequestStatus } as const;

export type RequestStatus = ValueOfObject<typeof requestStatus> | string;

export const requestOutcomeStatus = {
  ...requestStatus,
  archived: 'archived',
} as const;

export type RequestOutcomeStatus = ValueOfObject<typeof requestOutcomeStatus> | string;

export const requestListTab = {
  all: 'all',
  ...requestStatus,
} as const;

export type RequestListTab = ValueOfObject<typeof requestListTab>;

export const requestType = {
  ad: 'ad',
  talkingHead: 'talking_head',
  youtubeVideo: 'yt_video',
  podcast: 'podcast',
  vlog: 'vlog',
  reel: 'reel',
  tiktok: 'tiktok',
  youtubeShorts: 'yt_shorts',
  story: 'story',
  courseVideo: 'course_video',
  tutorial: 'tutorial',
  motionGraphics: 'motion_graphics',
  other: 'other',
} as const;

export type RequestType = ValueOfObject<typeof requestType>;

export type StoreOutcomeRequest = {
  type: string;
  title: string;
  description?: string;
  total_length?: TotalLengthRequest;
};

export const revisionType = { ...legacyRevisionType } as const;

export type RevisionType = ValueOfObject<typeof revisionType> | string;

export type TotalLengthRequest = {
  text?: string | number | null;
  value?: number;
  unit?: string;
};

export type TotalLengthResponse = {
  value?: number;
  unit?: string;
};

export type StoreRevisionRequest = {
  type: RevisionType;
  media: MediaObject[];
};

export type ChangeStatusRequest = {
  status: RequestStatus;
  editor_note?: string;
  revision?: StoreRevisionRequest;
};

export type RequestOutcomeResponse = {
  id: string;
  status: RequestOutcomeStatus;
  title: string;
  type: string;
  description: string;
  total_length?: TotalLengthResponse;
  editor_note?: string;
  is_default: boolean;
  sort: number;

  revisions_count?: number;
  versions_count?: number;

  requested_reedit_at: string;
  created_at: string;
  updated_at: string;

  created_by: string;
  creator_id: string;
  company_id: string;
  parent_id: string;
  editor: {
    id: string;
    first_name: string;
    last_name: string;
    avatar?: MediaObject;
  };
  latest_revision?: {
    id: string;
    created_at: string;
    video?: MediaObject;
  };
};

export const requestFlow = {
  default: 'default',
  multiOutcome: 'multi_outcome',
  outcome: 'outcome',
  repurpose: 'repurpose',
} as const;

export type RequestFlow = ValueOfObject<typeof requestFlow> | string;

export const requestMultipleOutcomesType = {
  custom: 'custom',
  editorChoice: 'editor_choice',
} as const;

export type RequestMultipleOutcomesType = ValueOfObject<typeof requestMultipleOutcomesType>;

export type RequestResponse = {
  id: string;
  title: string;
  type: string;
  status: RequestStatus | string;
  editor_note: string;
  attention_text?: string;
  flow: RequestFlow;
  priority: RequestPriorityType;
  parent_id?: string;
  total_length?: TotalLengthResponse;

  reviewed: boolean;
  with_multiple_outcomes: boolean;

  resolutions?: {
    main_size?: string;
    resize?: string[];
  };
  description?: {
    /** @deprecated */
    total_length?: string;
    video_request_description?: string;
  };
  supporting_materials?: {
    assets_urls?: string[];
    music_preference?: string;
  };
  extra?: {
    subtitles?: string;
    end_card?: boolean;
    examples?: string;
  };
  outcome_type?: RequestMultipleOutcomesType;

  reviewed_by_customer?: string;
  reviewed_by_editor?: string;
  status_changed_at?: string;
  submitted_at?: string;
  approved_at?: string;
  requested_reedit_at?: string;
  requested_attention_at?: string;
  created_at: string;

  revisions_count?: number;
  outcomes_count?: number;

  assets?: {
    id: string;
    url: string;
    comment?: string;
  }[];
  editor?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    avatar?: MediaObject;
  };
  brand?: {
    id: string;
    name: string;
    description: string;
    colors?: { color: string }[];
    created_at: string;
  };
  company?: {
    id: string;
    name: string;
  };
  media?: {
    footage: MediaObject[];
  };
  video_template?: VideoTemplateResponse;

  revisions: RevisionResponse[];
};

export type ChangeEditorRequest = {
  editor_id: string;
};

export type UpdatePriorityRequest = {
  priority: RequestPriorityType;
};

export const requestActivityEvent = {
  created: 'created',
  updated: 'updated',
  deleted: 'deleted',
} as const;

export type RequestActivityEvent = ValueOfObject<typeof requestActivityEvent>;

export const requestActivityType = {
  status: 'status',
  approval: 'approval',
  reedit: 'reedit',
  attention: 'attention',
} as const;

export type RequestActivityType = ValueOfObject<typeof requestActivityType>;

export type RequestActivityResponse = {
  id: string;
  event: RequestActivityEvent;
  type: RequestActivityType;
  data: RequestActivityData;
  created_at: string;
  updated_at: string;
  user?: UserResponse;
};

export type RequestActivityData = null | Record<string, unknown> | RequestActivityStatusChangeData;

export type RequestActivityStatusChangeData = {
  from: RequestStatus;
  to: RequestStatus;
};

export type FilteredRequestsOutcomesCountResponse = {
  total_outcomes: number;
  total_defaults: number;
};
